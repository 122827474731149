import Head from 'next/head'
import dynamic from 'next/dynamic'
import Header from '../components/header'
const Footer = dynamic(() => import('../components/footer'))
import Noresults from '../components/noresults';
const MiniCart = dynamic(() => import('../components/cart/minicart'))

function Custom404({ menu, config }) {


  return (
    <>
      <Head>
        <title>404 - Not Found</title>
        <meta name="robots" content="noindex"/>
      </Head>
      <div className="wrapper h-full flex flex-col">
        <Header menu={menu} config={config}></Header>
        <Noresults></Noresults>
        <Footer menu={menu} config={config}></Footer>
      </div>
      <MiniCart></MiniCart>
    </>
  )
}


// This function gets called at build time on server-side.
// It may be called again, on a serverless function, if
// revalidation is enabled and a new request comes in
export async function getStaticProps(context) {
  const config_res = await fetch(process.env.NEXT_PUBLIC_API_URL + '/configs')
  const config_data = await config_res.json()
  const config = config_data.data

  const menu_res = await fetch(process.env.NEXT_PUBLIC_API_URL + '/menu')
  const menu = await menu_res.json()


  return {
    props: {
      config: config,
      menu: menu,
    },
    // Next.js will attempt to re-generate the page:
    // - When a request comes in
    // - At most once every 10 seconds
    revalidate: 600, // In seconds
  }
}


export default Custom404