import React from 'react'
import Link from 'next/link'

function Noresults({ item }) {
    return (

        <div className="my-2 lg:my-16 w-full">
            <div className="container mx-auto lg:mb-4">
                <div className="flex justify-center">
                    <div className="lg:w-1/2 lg:px-0 px-5">
                        <div className="bg-gray-100 border border-gray-200 rounded-lg p-5 lg:p-8 text-center">
                            <h3 className="text-gray-900 mb-4">No Results Found</h3>
                            <p className="text-gray-700 mb-2">Sorry, we couldn&apos;t find any matches for your search.</p>
                            <p className="text-gray-700">Try searching for other, related words or <Link href="/contact"><a className="text-brand-primary underline font-semibold hover:no-underline">get in touch with us</a></Link> to get help from one of our expert staff.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Noresults;